<template>
  <CriaNot />
</template>

<script>
import CriaNot from "@/components/noticias/CriaNoticia.vue";

export default {
  components: {
    CriaNot
  }
};
</script>
