<template>
  <v-card class="pa-3" flat>
    <!-- Header -->
    <v-card-title class="clav-content-title-1"> Nova Notícia </v-card-title>

    <!-- Content -->
    <v-card-text>
      <Campo nome="Publicada" color="neutralpurple">
        <template v-slot:conteudo>
          <v-radio-group v-model="noticia.ativa" row dense hide-details>
            <v-radio label="Sim" :value="true"></v-radio>
            <v-radio label="Não" :value="false"></v-radio>
          </v-radio-group>
        </template>
      </Campo>

      <Campo nome="Título" color="neutralpurple">
        <template v-slot:conteudo>
          <v-text-field
            clearable
            color="primary"
            counter="150"
            single-line
            v-model="noticia.titulo"
            maxlength="150"
            dense
          ></v-text-field>
        </template>
      </Campo>

      <Campo nome="Data" color="neutralpurple">
        <template v-slot:conteudo>
          <v-menu
            ref="open"
            v-model="open"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="noticia.data"
                hint="AAAA-MM-DD"
                persistent-hint
                @blur="date = parseDate(dateFormatted)"
                v-on="on"
                dense
                :rules="regraData"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="open = false"
              :max="dateCurrent"
            ></v-date-picker>
          </v-menu>
        </template>
      </Campo>

      <v-row>
        <v-col cols="12">
          <v-card class="pa-4 mb-2" color="neutralpurple">
            <v-row class="pa-0 ma-0" justify="center">
              <span class="clav-info-label">Descrição</span>
            </v-row>
          </v-card>
        </v-col>
        <Editor v-model="noticia.desc"></Editor>
      </v-row>
    </v-card-text>
    <!-- Painel Operações -->
    <PainelOpsNot :t="noticia" :acao="'Criação'" />
  </v-card>
</template>

<script>
import Campo from "@/components/generic/CampoCLAV.vue";
import PainelOpsNot from "@/components/noticias/PainelOperacoesNoticias.vue";
import Editor from "@/components/generic/EditorMarkdown.vue";

export default {
  data: (nt) => ({
    noticia: {
      titulo: "",
      data: "",
      desc: "",
      ativa: false,
    },
    dateCurrent: new Date().toISOString().substr(0, 10),
    date: new Date().toISOString().substr(0, 10),
    dateFormatted: nt.formatDate(new Date().toISOString().substr(0, 10)),
    regraData: [
      (v) => /[0-9]+-[0-9]+-[0-9]+/.test(v) || "Este campo está no formato errado.",
    ],
    open: false,
    snackbar: false,
    text: "",
  }),

  components: {
    PainelOpsNot,
    Editor,
    Campo,
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(val) {
      this.noticia.data = this.formatDate(this.date);
    },
  },

  methods: {
    // vuetify datepicker
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month}-${day}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    // fechar o snackbar em caso de erro
    fecharSnackbar() {
      this.snackbar = false;
    },
  },
  created: function () {
    this.noticia.data = this.dateFormatted;
  },
};
</script>

<style scoped>
.expansion-panel-heading {
  background-color: #283593 !important;
  color: #fff;
  font-size: large;
  font-weight: bold;
}

.info-label {
  color: #283593; /* indigo darken-3 */
  padding: 5px;
  font-weight: 400;
  width: 100%;
  background-color: #e8eaf6; /* indigo lighten-5 */
  font-weight: bold;
  margin: 5px;
  border-radius: 3px;
}
</style>
